[
  {
    "names": [
      "tailwind.css"
    ],
    "generatedName": "tailwind.1c98d8fbca982082cf444dd068508b7f.css"
  },
  {
    "names": [
      "pl-home--__index.map"
    ],
    "generatedName": "-__index.973e11ddc815d9c175b110268f481e97.map.json"
  },
  {
    "names": [
      "pl-home--__index-bundle.js"
    ],
    "generatedName": "-__index-bundle.ca8922cda9a19f96fdcb17538010feb9.js"
  },
  {
    "names": [
      "pl-home-posts.map"
    ],
    "generatedName": "posts.9e53842aa07625d7ad4495b4cdc5238c.map.json"
  },
  {
    "names": [
      "pl-home-posts-bundle.js"
    ],
    "generatedName": "posts-bundle.25cdd6d8203b365cf8d0c4ae3cf0a48e.js"
  },
  {
    "names": [
      "pl-home-anti-spam-policy.map"
    ],
    "generatedName": "anti-spam-policy.2fa44fd485695ebe16abb7a9be391d66.map.json"
  },
  {
    "names": [
      "pl-home-anti-spam-policy-bundle.js"
    ],
    "generatedName": "anti-spam-policy-bundle.ff9613f2a9b238ee57924943e453f02c.js"
  },
  {
    "names": [
      "pl-home-privacy-policy.map"
    ],
    "generatedName": "privacy-policy.73931f0a8477b38c05a383481b6d6ce6.map.json"
  },
  {
    "names": [
      "pl-home-privacy-policy-bundle.js"
    ],
    "generatedName": "privacy-policy-bundle.db7b11fde1edccdf8324d9b52d2c47cd.js"
  },
  {
    "names": [
      "pl-home-robots_txt.map"
    ],
    "generatedName": "robots_txt.6bdd708db126b9c0c925083fcb22a888.map.json"
  },
  {
    "names": [
      "pl-home-robots_txt-bundle.js"
    ],
    "generatedName": "robots_txt-bundle.ff2368a98d0841106f8eef5a5ff9fe41.js"
  },
  {
    "names": [
      "pl-home-sitemap_xml.map"
    ],
    "generatedName": "sitemap_xml.9835dc0cbb5efc1983a5c93805e9767b.map.json"
  },
  {
    "names": [
      "pl-home-sitemap_xml-bundle.js"
    ],
    "generatedName": "sitemap_xml-bundle.bfa23fe7a9626811d9a94205df5589aa.js"
  }
]